import { DeepPartial } from '@empiriecom/module-components/utils/interfaces';
import deepmerge from '@/src/utils/deepmergeconfig';
import { tenantConfig } from '@empiriecom/mybuy-components/config/quellede';
import defaultConfig from './default';
import { FragmentConfig } from './types';

const quelledeconfig: DeepPartial<FragmentConfig> = {
  ...tenantConfig,
  l10n: {
    'de-DE': ['base-de', 'quellede-de'],
  },
  companyId: 'quellede',
  tracking: {
    gtmId: 'GTM-PWVG2XP',
    soastaId: '38EQD-2PT6K-8M2C6-RPG2A-MLT97',
  },
  bookings: {
    overview: {
      showOpenPayment: 'sum-and-open',
    },
  },
  permissions: {
    eInvoiceEnabled: false,
  },
  show: {
    ...tenantConfig.show,
    ibanBeforeBic: true,
  },
  lounge: {
    banner: (language: string, device: string) =>
      `https://bilder.quelle.de/empiriecom/io/quellede/${language}/lounge_banner_${device}.jpg`,
    premiumContentSubscription: (language: string) =>
      `https://fragment-content-external.quellede.empirie.cloud/mein-konto/premium-subscription-${language}`,
    premiumContentDummy: (language: string) =>
      `https://fragment-content-external.quellede.empirie.cloud/mein-konto/premium-dummy-${language}`,
    switchModalButtons: true,
    premiumOnTop: true,
  },
  images: {
    logoSmall: {
      default: 'https://bilder.baur.de/i/empiriecom/quelle_logo_small.png',
    },
  },
  returnLabelShown: true,
  flexikonto: {
    enabled: true,
    rsvFeeIncluded: true,
    paymentProtectionAgeBreakpoint: 150,
    externalUrls: {
      faq: 'https://fragment-content-external.quellede.empirie.cloud/de/mein-konto/mein-flexikonto',
      paymentPause: {
        default: '/service-hilfe/bezahlung-finanzierung/flexikonto-zahlpause',
      },
      paymentProtection: {
        default: '/service-hilfe/bezahlung-finanzierung/flexikonto-zahlschutz',
      },
    },
  },
  checkMarkColor: 'primary',
};

export default deepmerge(defaultConfig, quelledeconfig) as FragmentConfig;
